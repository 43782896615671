<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Cập nhật thuộc tính'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="col-md-12 mb-4">
                <div class="row">
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label>Nhóm thuộc tính:</label>
                    </b-row>
                    <b-row class="my-1">
                      <b-form-select
                        size="sm"
                        id="input-1"
                        v-model="selectedGroup"
                        :options="listGroup"
                        required
                        v-bind:disabled="
                          checkPermission('PROPERTY_UPDATE') ? false : true
                        "
                        value-field="id"
                        text-field="name"
                        class="mb-3 select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn nhóm thuộc tính</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label>Thuộc tính cha:</label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          v-bind:disabled="
                            checkPermission('PROPERTY_UPDATE') ? false : true
                          "
                          size="sm"
                          id="input-2"
                          v-model="selectedParent"
                          :options="listProperty"
                          required
                          value-field="id"
                          text-field="name"
                          class="mb-3 select-style"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Chọn thuộc tính cha</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label>Tên thuộc tính:
                        <span class="text-danger">*</span>
                      </label>
                    </b-row>
                    <b-row class="my-1">
                      <b-input
                        type="text"
                        v-bind:disabled="
                          checkPermission('PROPERTY_UPDATE') ? false : true
                        "
                        id="inline-form-input-name"
                        placeholder="Nhập tên thuộc tính"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-12 form-control-sm"
                        v-model="$v.form.propertyName.$model"
                        :state="validateState('propertyName')"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        Nhập tên thuộc tính với ít nhất 3 ký tự!
                      </b-form-invalid-feedback>
                      <span
                        style="fontSize:0.9rem;fontWeight:400" 
                        class="text-danger" 
                        v-if="!checkValidData">
                        Tên thuộc tính không được chứa ký tự đặc biệt
                      </span>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label>Kiểu dữ liệu:
                          <span class="text-danger">*</span>
                        </label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          v-bind:disabled="
                            checkPermission('PROPERTY_UPDATE') ? false : true
                          "
                          size="sm"
                          id="input-3"
                          v-model="selectedDataType"
                          :options="listDataType"
                          @change="onChangeType"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          class="select-style"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Chọn kiểu dữ liệu</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                        <span
                          style="fontSize:0.9rem;fontWeight:400" 
                          v-if="showErrorType" 
                          class="text-danger">
                          Vui lòng chọn kiểu dữ liệu
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <p>
                <strong>Giá trị</strong>
              </p>
              <div class="mb-4">
                <button
                  class="btn btn-primary btn-sm"
                  @click="addRow"
                  v-show="checkPermission('PROPERTY_UPDATE')"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                  mới
                </button>
              </div>
              <div class="col-md-6" style="padding-left: 0">
                <table
                  class="table table-bordered table-vertical-center table-hover"
                >
                  <thead>
                    <tr>
                      <th style="textalign: center; color: rgb(24, 28, 50)">
                        STT
                      </th>
                      <th scope="col">Tên</th>
                      <th scope="col">Giá trị</th>
                      <th scope="col">Mã</th>
                      <th
                        scope="col"
                        v-show="checkPermission('PROPERTY_UPDATE')"
                      ></th>
                    </tr>
                  </thead>

                  <tbody
                    v-for="(item, index) in listProductPropertyValue"
                    :key="index"
                  >
                    <ProductPropertyValue
                      v-bind:item="item"
                      v-on:updateItem="updateValue"
                      v-bind:listFeature="listFeature"
                      v-on:deleteItem="deleteValue"
                      v-on:addItem="addNewValue"
                      v-on:cancelAdd="cancelAdd"
                    />
                  </tbody>
                </table>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="updateProperty"
              v-if="checkPermission('PROPERTY_UPDATE')"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/properties" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
input::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import ProductPropertyValue from '../../components/ProductPropertyValue.vue';
import localData from '../../../utils/saveDataToLocal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnStyle: {
        fontWeight: '600!important',
      },
      valid: true,
      form: {
        propertyName: '',
      },
      selectedGroup: null,
      listGroup: [],
      selectedDataType: null,
      listDataType: [
        { id: 1, name: 'Color' },
        { id: 2, name: 'Size' },
        { id: 3, name: 'Storage' },
        { id: 4, name: 'Status' },
      ],
      selectedParent: null,
      listProperty: [],
      selectedId: null,
      itemChoice: null,
      listFeature: [],
      listProductPropertyValue: [],
      propertyId: 0,
      showErrorType: false,
    };
  },
  validations: {
    form: {
      propertyName: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
    ProductPropertyValue,
  },
  created() {
    this.getListPropertyGroup();
    this.getListProperty();
    this.getListFeature();
    this.propertyId = this.$route.query.id;
    this.getPropertyById();
  },
  mounted() {
    if (this.checkPermission('PROPERTY_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Thuộc tính', route: '/properties' },
        { title: 'Danh sách thuộc tính', route: '/properties' },
        { title: 'Cập nhật thuộc tính' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Thuộc tính', route: '/properties' },
        { title: 'Danh sách thuộc tính', route: '/properties' },
        { title: 'Thông tin thuộc tính' },
      ]);
    }
  },
  computed: {
    checkValidData(){ 
      if (!this.isValidData(this.$v.form.propertyName.$model)){ 
        return false
      } 
      return true;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChangeType(){
      this.showErrorType = false;
    },
    updateProperty: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError || !this.selectedDataType) {
        this.showErrorType = true
        if (this.selectedDataType){ 
          this.showErrorType = false;
        }
        return;
      }
      if (!this.checkValidData) {
        return;
      }
      if (this.listProductPropertyValue.length === 0) {
        this.makeToastFaile('Vui lòng nhấn nút Thêm mới để thêm giá trị !');
        return;
      }
      if (
        !this.listProductPropertyValue[0].name &&
        !this.listProductPropertyValue[0].code &&
        !this.listProductPropertyValue[0].value
      ) { 
        this.makeToastFaile('Vui lòng điền các trường Tên, Giá trị, Mã !');
        return;
      }
      const name = this.$v.form.propertyName.$model;
      const productCategoryId = null;
      const productPropertyGroupId = this.selectedGroup;
      const productPropertyParentId = this.selectedParent;
      const type = this.selectedDataType;
      let data = {
        id: this.propertyId,
        name: name,
        productCategoryId: productCategoryId,
        productPropertyGroupId: productPropertyGroupId,
        productPropertyParentId: productPropertyParentId,
        type: type,
      };
      ApiService.setHeader();
      ApiService.post('property/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getListPropertyGroup: async function () {
      this.listGroup = [];
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('propertyGroup', paramQuery).then(({ data }) => {
        data.data.list_property_group.forEach((element) => {
          if (element.name){ 
            const item = {
              id: element.id,
              name: element.name,
            };
          this.listGroup.push(item);
          }
        });
      });
    },
    getListProperty: async function () {
      this.listProperty = [];
      //  init params
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('property', paramQuery)
        .then(({ data }) => {
          data.data.list_property.forEach((item) => {
            let property = {
              id: item.id,
              name: item.name,
            };
            this.listProperty.push(property);
          });
        })
        .catch(() => {});
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    getListFeature() {
      this.listFeature = localData.getData('listFeature');
    },
    addRow() {
      const data = {
        id: '',
        name: '',
        createAt: '',
      };
      this.listProductPropertyValue.unshift(data);
    },
    cancelAdd() {
      this.listProductPropertyValue.shift();
    },
    addNewValue(item) {
      const data = {
        name: item.name,
        value: item.value,
        code: item.code,
        productPropertyId: this.propertyId,
      };
      ApiService.setHeader();
      ApiService.post('propertyValue/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchPropertyValue();
          } else {
            this.makeToastFaile(data.message);
            this.cancelAdd();
          }
        })
        .catch(() => {});
    },
    updateValue(item) {
      const data = {
        id: item.id,
        name: item.name,
        value: item.value,
        productPropertyId: this.propertyId,
      };
      ApiService.setHeader();
      ApiService.post('propertyValue/update', data).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchPropertyValue();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    deleteValue(id) {
      ApiService.setHeader();
      ApiService.delete(`propertyValue/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchPropertyValue();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(() => {});
    },
    getPropertyById() {
      ApiService.setHeader();
      ApiService.get(`property/${this.propertyId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.form.propertyName = data.data.name;
            this.selectedGroup = data.data.productPropertyGroupId;
            this.selectedParent = data.data.productPropertyParentId;
            this.selectedDataType = data.data.type;
            let count = 1;
            data.data.ProductPropertyValues.forEach((element) => {
              if (element.name){ 
                const item = {
                  count: count,
                  id: element.id,
                  name: element.name,
                  value: element.value,
                  code: element.code,
                };
                count++;
                this.listProductPropertyValue.push(item);
              }
            });
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchPropertyValue() {
      this.listProductPropertyValue = [];
      const param = {
        page: 1,
        limit: 10,
        propertyId: this.propertyId,
      };
      const paramQuery = {
        params: param,
      };
      let count = 1;
      ApiService.setHeader();
      ApiService.query('propertyValue', paramQuery).then(({ data }) => {
        data.data.list_property_value.forEach((element) => {
          if (element.name){ 
            const item = {
              count: count,
              id: element.id,
              name: element.name,
              value: element.value,
              code: element.code,
          };
          count++;
          this.listProductPropertyValue.push(item);
          }
        });
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
  },
};
</script>
